import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { createBrowserHistory } from 'history';
import { isUserAuthenticated } from '../helpers/authUtils';
import { Ticket } from '../components/Unicon';

const Unicons = {
    ticket: (props) => <Ticket alt="vouchers" {...props} align="vertical" inline />
}


// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Profile = React.lazy(() => import('../pages/profile'));
const NewPassword = React.lazy(() => import('../pages/auth/NewPassword'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ValidateEmail = React.lazy(() => import('../pages/auth/ValidateEmail'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// apps
const Reports = React.lazy(() => import('../pages/reports'));
const FormEditAdventure = React.lazy(() => import('../pages/adventures/FormEditAdventure'));
const FormNewAdventure = React.lazy(() => import('../pages/adventures/FormNewAdventure'));
const Campaign = React.lazy(() => import('../pages/campaign'));
const List = React.lazy(() => import('../pages/adventures/List'));
const Finances = React.lazy(() => import('../pages/finances'));
const Vouchers = React.lazy(() => import('../pages/vouchers'));
const Clients = React.lazy(() => import('../pages/clients'));
const ScheduleFull = React.lazy(() => import('../pages/schedule/Full'));
const ScheduleMinimal = React.lazy(() => import('../pages/schedule/Minimal'));
const ScheduleNextEvents = React.lazy(() => import('../pages/schedule/NextEvents'));
const ShowSchedule = React.lazy(() => import('../pages/schedule/ShowSchedule'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            // const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Home',
    icon: FeatherIcon.Home,
    component: Dashboard,
    roles: ['Admin'],
    route: PrivateRoute
};

// Reservas

const schedulesAppRoutes = {
    name: 'Agenda',
    icon: FeatherIcon.Calendar,
    route: PrivateRoute,
    children: [
        {
            path: '/schedule/events',
            name: 'Agenda Completa',
            component: ScheduleFull,
            route: PrivateRoute,
        },
        {
            path: '/schedule/minimal',
            name: 'Disponibilidade',
            component: ScheduleMinimal,
            route: PrivateRoute,
        },
        {
            path: '/schedule/next',
            name: 'Próximos Eventos',
            component: ScheduleNextEvents,
            route: PrivateRoute,
        },
    ],
    roles: ['Admin']
};

const reportsRoutes = {
    path: '/reports',
    name: 'Relatórios',
    icon: FeatherIcon.BarChart2,
    component: Reports,
    roles: ['Admin'],
    route: PrivateRoute
}

const eventRoutes = {
    path: '/schedule/events/:id',
    component: ShowSchedule,
    route: PrivateRoute,
    roles: ['Admin'],
}

// Aventuras

const adventuresAppRoutes = {
    path: '/adventures/all',
    name: 'Experiências',
    icon: FeatherIcon.Map,
    component: List,
    route: PrivateRoute,
    roles: ['Admin']
};

const createAdventureRoute = {
    path: '/adventures/new',
    name: 'Nova experiências',
    component: FormNewAdventure,
    route: PrivateRoute,
    roles: ['Admin'],
}

const editAdventureRoute = {
    path: '/adventures/edit/:id',
    component: FormEditAdventure,
    route: PrivateRoute,
    roles: ['Admin']
}

// Campanhas
const campaignRoute = {
    path: '/campaign',
    name: 'Campanhas',
    icon: FeatherIcon.Crosshair,
    component: Campaign,
    route: PrivateRoute,
    roles: ['Admin']
};

const vouchersAppRoutes = {
    path: '/vouchers',
    name: 'Ingressos',
    icon: FeatherIcon.Server,
    component: Vouchers,
    route: PrivateRoute,
};

const clientsAppRoutes = {
    path: '/clients',
    name: 'Clientes',
    icon: FeatherIcon.Users,
    component: Clients,
    route: PrivateRoute,
    roles: ['Admin'],
};

const financesAppRoutes = {
    path: '/finances',
    name: 'Conta Juno',
    icon: FeatherIcon.DollarSign,
    component: Finances,
    route: PrivateRoute,
    roles: ['Admin']
};

const appRoutes = [
    schedulesAppRoutes, 
    vouchersAppRoutes,
    reportsRoutes,
    clientsAppRoutes,
    adventuresAppRoutes,
    campaignRoute,
    financesAppRoutes,
    // emailAppRoutes, 
    // projectAppRoutes, 
    // taskAppRoutes
];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/profile',
            name: 'Perfil',
            component: Profile,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/account/new-password',
            name: 'Trocar senha',
            component: NewPassword,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/validate-email/:token',
            name: 'ValidateEmail',
            component: ValidateEmail,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    dashboardRoutes,
    eventRoutes,
    createAdventureRoute,
    editAdventureRoute,
    ...appRoutes,
    // pagesRoutes,
    // componentsRoutes,
    // chartRoutes,
    // formsRoutes,
    // tableRoutes,
    authRoutes,
];

const authProtectedRoutes = [
    dashboardRoutes, 
    ...appRoutes, 
    // pagesRoutes, 
    // componentsRoutes, 
    // chartRoutes, 
    // formsRoutes, 
    // tableRoutes
];

export const refreshBlockedRoutes = [
    'adventures',
    'adventures',
    'vouchers',
    'profile',
    'schedule/events'
]

const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };

export const history = createBrowserHistory();