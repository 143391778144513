
import { all } from 'redux-saga/effects';
import adventuresSaga from './adventures/saga';
import appMenuSaga from './appMenu/saga';
import authSaga from './auth/saga';
import financesSaga from './finances/saga';
import layoutSaga from './layout/saga';
import reservationSaga from './reservation/saga';
import vouchersSaga from './vouchers/saga';
import clientsSaga from './clients/saga';
import campaignSaga from './campaign/saga';
import activitiesSaga from './activities/saga';
import appSaga from './app/saga';
import notesSaga from './notes/saga';

export default function* rootSaga() {
    yield all([
        appSaga(),
        authSaga(), 
        layoutSaga(), 
        appMenuSaga(), 
        reservationSaga(), 
        adventuresSaga(),
        financesSaga(),
        vouchersSaga(),
        clientsSaga(),
        activitiesSaga(),
        notesSaga(),
        campaignSaga()
    ]);
}
