import {
    FETCH_FINANCES_BALANCES,
    FETCH_FINANCES_BALANCES_SUCCESS,
    FETCH_FINANCES_BALANCES_ERROR,
    FETCH_FINANCES_EXTRACT_MONTH,
    FETCH_FINANCES_EXTRACT_MONTH_SUCCESS,
    FETCH_FINANCES_EXTRACT_MONTH_ERROR,
    FETCH_FINANCES_TRANSFER,
    FETCH_FINANCES_TRANSFER_SUCCESS,
    FETCH_FINANCES_TRANSFER_ERROR,
    CLEAR_ERROR,
    CLEAR_TRANSFERENCE,
    LOGOUT_USER
} from '../constants';
import { FETCH_FINANCES_FUTURE_EXTRACT, FETCH_FINANCES_FUTURE_EXTRACT_ERROR, FETCH_FINANCES_FUTURE_EXTRACT_SUCCESS } from './constants';

const initialState = {
    balances: { current: 0, future: 0, unavailable: 0 },
    extractMonth: [],
    extractFuture: [],
    transference: null,
    error: null,
    loadingBalances: false,
    loadingTransfer: false,
    loadingExtractFuture: false,
    loadingExtractMonth: false
}

const Finances = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_FINANCES_BALANCES:
            return { ...state, loadingBalances: true }
        
        case FETCH_FINANCES_BALANCES_SUCCESS:
            return { ...state, loadingBalances: false, balances: action.payload }

        case FETCH_FINANCES_BALANCES_ERROR:
            return { ...state, loadingBalances: false, error: action.payload }
        
        case FETCH_FINANCES_EXTRACT_MONTH:
            return { ...state, loadingExtractMonth: true }

        case FETCH_FINANCES_EXTRACT_MONTH_SUCCESS:
            return { ...state, loadingExtractMonth: false, extractMonth: action.payload }

        case FETCH_FINANCES_EXTRACT_MONTH_ERROR:
            return { ...state, loadingExtractMonth: false, error: action.payload }
        
            case FETCH_FINANCES_FUTURE_EXTRACT:
            return { ...state, loadingExtractFuture: true }

        case FETCH_FINANCES_FUTURE_EXTRACT_SUCCESS:
            return { ...state, loadingExtractFuture: false, extractFuture: action.payload }

        case FETCH_FINANCES_FUTURE_EXTRACT_ERROR:
            return { ...state, loadingExtractFuture: false, error: action.payload }

        case FETCH_FINANCES_TRANSFER:
            return { ...state, loadingTransfer: true, data: action.payload }

        case FETCH_FINANCES_TRANSFER_SUCCESS:
            return { ...state, loadingTransfer: false, transference: action.payload }

        case FETCH_FINANCES_TRANSFER_ERROR:
            return { ...state, loadingTransfer: false, error: action.payload }
        
        case CLEAR_ERROR:
            return { ...state, error: null }

        case CLEAR_TRANSFERENCE:
            return { ...state, transference: null }

        case LOGOUT_USER:
            return initialState
            
        default:
            return state;
    }
}

export default Finances

