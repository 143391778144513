

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import Layout from './layout/reducers';
import Auth from './auth/reducers';
import App from './app/reducers';
import AppMenu from './appMenu/reducers';
import Reservation from './reservation/reducers'
import Vouchers from './vouchers/reducers'
import Adventures from './adventures/reducers'
import entities from './entities/reducers'
import Balances from './finances/reducers';
import Communication from './communication/reducers';
import Clients from './clients/reducers';
import Campaign from './campaign/reducers';
import Activities from './activities/reducers';
import Notes from './notes/reducers';

const authConfig = {
    key: 'auth',
    storage,
}

const appConfig = {
    key: 'app',
    storage: storageSession
}

const reservationConfig = {
    key: 'reservation',
    storage: storageSession
}

const adventuresConfig = {
    key: 'adventures',
    storage: storageSession
}

const vouchersConfig = {
    key: 'vouchers',
    storage: storageSession
}

const entitiesConfig = {
    key: 'entities',
    storage: storageSession
}

const balancesConfig = {
    key: 'balances',
    storage: storageSession
}

const clientsConfig = {
		key: 'clients',
		storage: storageSession
}

const activitiesConfig = {
		key: 'activities',
        storage: storageSession
}
const notesConfig = {
    key: 'notes',
    storage: storageSession
}
const communicationConfig = {
    key: 'communication',
    storage: storageSession
}

export default combineReducers({
    Auth: persistReducer(authConfig, Auth),
    app: persistReducer(appConfig, App),
    AppMenu,
    Layout,
    entities: persistReducer(entitiesConfig, entities),
    Reservation: persistReducer(reservationConfig, Reservation),
    Adventures: persistReducer(adventuresConfig, Adventures),
    Balances: persistReducer(balancesConfig, Balances),
    Vouchers: persistReducer(vouchersConfig, Vouchers),
    Communication: persistReducer(communicationConfig, Communication),
    Clients: persistReducer(clientsConfig, Clients),
    Activities: persistReducer(activitiesConfig, Activities),
    Campaign,
    Notes: persistReducer(notesConfig, Notes)
});

export const testReducers = combineReducers({
    Auth,
    AppMenu,
    Layout,
    entities,
    Reservation,
    Adventures
})