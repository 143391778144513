
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    CLEAR_ERROR,
    CLEAR_FORGET_PASSWORD,
    CREATE_PIX_KEY,
    CREATE_PIX_KEY_SUCCESS,
    CREATE_PIX_KEY_ERROR
} from '../constants';

import { getLoggedInUser } from '../../helpers/authUtils';
import { UPDATE_USER_FIELDS, SAVE_BANK_ATTRIBUTES_ERROR, SAVE_BUSINESS_ATTRIBUTES_ERROR, SAVE_BUSINESS_ATTRIBUTES, SAVE_BUSINESS_ATTRIBUTES_SUCCESS, SAVE_BANK_ATTRIBUTES, SAVE_BANK_ATTRIBUTES_SUCCESS, CLEAR_SUCCESS, SEND_DOCUMENTS, SEND_DOCUMENTS_SUCCESS, SEND_DOCUMENTS_ERROR, UPDATE_COMPANY_TAGS, UPDATE_COMPANY_TAGS_SUCCESS, UPDATE_COMPANY_TAGS_ERROR, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR, SAVE_MERCADO_PAGO_TOKEN, SAVE_MERCADO_PAGO_TOKEN_SUCCESS, SAVE_MERCADO_PAGO_TOKEN_ERROR, SAVE_PAG_SEGURO_CREDENTIALS, SAVE_PAG_SEGURO_CREDENTIALS_ERROR, SAVE_PAG_SEGURO_CREDENTIALS_SUCCESS, SAVE_GETNET_CREDENTIALS, SAVE_GETNET_CREDENTIALS_SUCCESS, SAVE_GETNET_CREDENTIALS_ERROR } from './constants';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    loadingSaveBusinessAttributes: false,
    loadingSaveBankAttributes: false,
    loadingRegisterUser: false,
    loadingUpdateUser: false,
    loadingSendDocuments: false,
    loadingCompanyTags: false,
    loadingChangePassword: false,
    loadingSaveMercadoPagoToken: false,
    loadingSavePagSeguroCredentials: false,
    loadingSaveGetnetCredentials: false,
    loadingPixKey: false,
    passwordResetStatus: null,
    error: null,
    success: null
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: { ...action.payload }, loading: false, error: null };
        case LOGIN_USER_ERROR:
            return { ...state, error: { ...action.payload }, loading: false };
        case REGISTER_USER:
            return { ...state, loadingRegisterUser: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: { ...action.payload }, loadingRegisterUser: false, error: null };
        case REGISTER_USER_ERROR:
            return { ...state, error: { ...action.payload }, loadingRegisterUser: false };
        case UPDATE_USER:
            return { ...state, loadingUpdateUser: true };
        case UPDATE_USER_SUCCESS:
            return { ...state, user: { ...action.payload }, loadingUpdateUser: false, error: null };
        case UPDATE_USER_ERROR:
            return { ...state, error: { ...action.payload }, loadingUpdateUser: false };
        case LOGOUT_USER:
            return { ...INIT_STATE, user: null };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: { ...action.payload }, loading: false, error: null };
        case FORGET_PASSWORD_ERROR:
            return { ...state, error: { ...action.payload }, loading: false };
        case CLEAR_ERROR:
            return { ...state, error: null };
        case CLEAR_FORGET_PASSWORD:
            return { ...state, passwordResetStatus: null };
        case UPDATE_USER_FIELDS:
            return { ...state, user: { ...state.user, ...action.payload }}
        case SAVE_BANK_ATTRIBUTES:
            return { ...state, loadingSaveBankAttributes: true, error: null, success: null }
        case SAVE_BANK_ATTRIBUTES_SUCCESS:
            return { ...state, loadingSaveBankAttributes: false, error: null, success: { message: "Dados salvos com sucesso!" } }
        case SAVE_BANK_ATTRIBUTES_ERROR:
            return { ...state, loadingSaveBankAttributes: false, success: null, error: { ...action.payload } }
        case SEND_DOCUMENTS:
            return { ...state, loadingSendDocuments: true, error: null, success: null }
        case SEND_DOCUMENTS_SUCCESS:
            return { ...state, loadingSendDocuments: false, error: null, success: { message: "Documentos salvos com sucesso!" } }
        case SEND_DOCUMENTS_ERROR:
            return { ...state, loadingSendDocuments: false, success: null, error: { ...action.payload } }
        case SAVE_BUSINESS_ATTRIBUTES:
            return { ...state, loadingSaveBusinessAttributes: true, error: null, success: null }
        case SAVE_BUSINESS_ATTRIBUTES_SUCCESS:
            const newState = { ...state, loadingSaveBusinessAttributes: false, error: null }

            if (action.payload.first_access)
                newState.user = { ...state.user, term_sign_date: action.payload.term_sign_date }

            if (!action.payload.silent)
                newState.success = { message: "Dados salvos com sucesso!" }
                
            return newState
        case SAVE_BUSINESS_ATTRIBUTES_ERROR:
            return { ...state, loadingSaveBusinessAttributes: false, success: null, error: { ...action.payload } }
        case CLEAR_SUCCESS:
            return { ...state, success: null }
        case UPDATE_COMPANY_TAGS:
            return { ...state, loadingCompanyTags: true }
        case UPDATE_COMPANY_TAGS_SUCCESS:
            return { ...state, loadingCompanyTags: false }
        case UPDATE_COMPANY_TAGS_ERROR:
            return { ...state, loadingCompanyTags: false, error: action.payload }
        case CHANGE_PASSWORD:
            return { ...state, loadingChangePassword: true, error: null, success: null }
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loadingChangePassword: false, success: { message: "Senha alterada com sucesso!" } }
        case CHANGE_PASSWORD_ERROR:
            return { ...state, loadingChangePassword: false, error: action.payload }
        case SAVE_MERCADO_PAGO_TOKEN:
            return { ...state, loadingSaveMercadoPagoToken: true, token: { ...action.payload } }
        case SAVE_MERCADO_PAGO_TOKEN_SUCCESS:
            return { ...state, loadingSaveMercadoPagoToken: false, success: { message: "Access Token cadastrado com sucesso!" } }
        case SAVE_MERCADO_PAGO_TOKEN_ERROR:
            return { ...state, loadingSaveMercadoPagoToken: false, error: action.payload }
        case SAVE_PAG_SEGURO_CREDENTIALS:
            return { ...state, loadingSavePagSeguroCredentials: true, data: { ...action.payload } }
        case SAVE_PAG_SEGURO_CREDENTIALS_SUCCESS:
            return { ...state, loadingSavePagSeguroCredentials: false, success: { message: "Credenciais cadastrada com sucesso!" } }
        case SAVE_PAG_SEGURO_CREDENTIALS_ERROR:
            return { ...state, loadingSavePagSeguroCredentials: false, error: action.payload }
        case SAVE_GETNET_CREDENTIALS:
            return { ...state, loadingSaveGetnetCredentials: true }
        case SAVE_GETNET_CREDENTIALS_SUCCESS:
            return { ...state, loadingSaveGetnetCredentials: false, success: { message: "Credenciais cadastrada com sucesso!" } }
        case SAVE_GETNET_CREDENTIALS_ERROR:
            return { ...state, loadingSaveGetnetCredentials: false, error: action.payload }
        case CREATE_PIX_KEY:
            return { ...state, loadingPixKey: true }
        case CREATE_PIX_KEY_SUCCESS: 
            return { ...state, loadingPixKey: false }
        case CREATE_PIX_KEY_ERROR:
            return { ...state, loadingPixKey: false, error: action.payload }
        default:
            return { ...state };
    }
};

export default Auth;
